<template>
    <leads-login v-if="this.$store.getters.whiteLabel === 'Leads'"></leads-login>
    <saleads-login v-else-if="this.$store.getters.whiteLabel === 'Saleads'"></saleads-login>
    <workle-login v-else-if="this.$store.getters.whiteLabel === 'Workle'"></workle-login>
    <v-row v-else>
        <v-col cols="12" lg="7" xl="6" class="leftside d-none d-md-flex align-center justify-center position-relative">
            <v-container>
                <div class="pa-10">
                    <v-row justify="center">
                        <v-col cols="8" xl="5">
                            <div>
                                <h2
                                    class="display-1 white--text font-weight-medium"
                                >CPAider</h2>
                                <h6
                                    class="subtitle-1 mt-4 white--text op-5 font-weight-regular"
                                >{{ trans('pages.auth.authText') }}</h6>
                                <v-btn class="mt-4 text-capitalize" x-large outlined color="white" :href="backendUrl">{{ trans('buttons.common.home') }}</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px;">
            </v-container>
        </v-col>
        <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
            <v-container>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px; max-width: 70px" class="d-block d-md-none">
                <div class="pa-7 pa-sm-12">
                    <v-row>
                        <v-col cols="12">
                            <lang-switcher></lang-switcher>
                        </v-col>
                        <v-col cols="12" lg="9" xl="6" class="text-center">
                            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">{{ trans('pages.auth.signIn') }}</h2>
                            <h6 class="subtitle-1">
                                {{ trans('pages.login.noAccount')}}
                                <router-link to="register">{{ trans('pages.auth.register') }}</router-link>
                            </h6>

                            <v-form ref="form" v-model="valid" lazy-validation action="/dashboards/analytical">
                                <v-text-field
                                    v-model="email"
                                    :label="trans('pages.fields.email')"
                                    :error-messages="errors.email"
                                    class="mt-4"
                                    required
                                    outlined
                                ></v-text-field>
                                <v-text-field
                                    v-model="password"
                                    :error-messages="errors.password"
                                    :label="trans('pages.fields.password')"
                                    required
                                    outlined
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'"
                                ></v-text-field>

                                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                                    <div class="ml-auto">
                                        <router-link :to="{name: 'forgot'}" class="link">{{ trans('pages.login.forgotPwd') }}</router-link>
                                    </div>
                                </div>
                                <v-btn
                                    color="info"
                                    block
                                    class="mr-4"
                                    submit
                                    @click="submit"
                                >{{ trans('pages.buttons.signIn') }}</v-btn>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    import LangSwitcher from "@/components/commonComponents/LangSwitcher";
    import store from "@/store";
    import router from "@/router";
    const env = require('@/env.json');
    // const axios = window.axios;
    export default {
        name: "FullLogin",
        components: {
            LangSwitcher,
            LeadsLogin: () => import('@/views/authentication/whiteLabel/Leads'),
            WorkleLogin: () => import('@/views/authentication/whiteLabel/Workle'),
            SaleadsLogin: () => import('@/views/authentication/whiteLabel/Saleads')
        },
        data: () => ({
            valid: true,
            password: "",
            show1: false,
            passwordRules: [
                v => !!v || "Password is required",
                v => (v && v.length <= 10) || "Password must be less than 10 characters"
            ],
            email: "",
            emailRules: [
                v => !!v || "E-mail is required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            checkbox: false,
            errors:{},
        }),
        created() {
        },
        computed: {
            backendUrl() {
                return env.backendUrl;
            }
        },
        methods: {
            submit() {
                axios.post(this.route('login'), {
                    email: this.email,
                    password: this.password
                }).then(() => {
                    this.$store.dispatch('getAuth').then( () => {
                        if (this.$store.getters.isAdmin){
                            this.$router.addRoutes(require('@/routerAdmin').default)
                        }
                        if (store.getters.isCpaNetworkManager){
                            router.addRoutes(require('@/routerCpaNetworkManager').default)
                        }
                        if (store.getters.isMaster){
                            router.addRoutes(require('@/routerMaster').default)
                        }
                        this.$router.push({ name: this.$store.getters.homeRouteName });
                    });
                }).catch( err => {
                    this.errors = err.response.data.errors;
                });
            }
        }
    };
</script>
<style scoped>
    .leftside {
        background: url("../../assets/images/web-web.svg") no-repeat center right;
        /*background-image: linear-gradient(-90deg, #1d88e5 0%, #3998ea 100%);*/
    }
</style>
